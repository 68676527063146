<template>
  <div id="app-mobile">
    <!--    :class="
        switchLanguage == 'zh'
          ? this.watchClientWidth > 600
            ? 'header_img1 header_img3'
            : 'header_img1 header_img4'
          : this.watchClientWidth > 600
          ? 'header_img2 header_img3'
          : 'header_img2 header_img4'
      " -->
    <div
      class="header_con"
      :class="{
        header_img3: this.watchClientWidth > 600,
        header_img4: this.watchClientWidth <= 600,
        height0: !flagSwiper
      }"
    >
      <div class="header_banner" v-if="flagSwiper" ref="header_banner">
        <swiper
          v-show="switchLanguage == 'zh'"
          class="header_banner_swiper"
          :options="backSwiperOption"
          :class="this.watchClientWidth > 600 ? 'header_img3' : 'header_img4'"
        >
          <swiper-slide
            class="swiper-slide"
            v-for="(item, index) in bannerList"
            :key="index"
          >
            <img class="banner_img" :src="item.zhUrl" alt="" />
          </swiper-slide>

          <div
            class="swiper-pagination"
            slot="pagination"
            :class="
              this.watchClientWidth > 600
                ? 'swiper-pagination1'
                : 'swiper-pagination2'
            "
          ></div>
        </swiper>
        <swiper
          v-show="switchLanguage == 'en'"
          class="header_banner_swiper"
          :options="backSwiperOption"
          :class="this.watchClientWidth > 600 ? 'header_img3' : 'header_img4'"
        >
          <swiper-slide
            class="swiper-slide"
            v-for="(item, index) in bannerList1"
            :key="index"
          >
            <img class="banner_img" :src="item.enUrl" alt="" />
          </swiper-slide>

          <div
            class="swiper-pagination"
            slot="pagination"
            :class="
              this.watchClientWidth > 600
                ? 'swiper-pagination1'
                : 'swiper-pagination2'
            "
          ></div>
        </swiper>
        <div
          class="swiper-num"
          :class="this.watchClientWidth > 600 ? 'swiper-num2' : 'swiper-num1'"
        ></div>
      </div>
      <div
        class="header_list"
        :class="this.watchClientWidth > 600 ? 'header_list1' : 'header_list2'"
      >
        <div class="logo_con">
          <img
            src="https://website-1308146035.cos.ap-shanghai.myqcloud.com/logo_zh.png"
            alt=""
          />
        </div>
        <div
          class="tabs_con"
          :class="{ black: !flagSwiper }"
          v-if="this.watchClientWidth > 600"
        >
          <div
            class="tabs_item"
            v-for="(item, index) in tabsList"
            :key="index"
            :class="activeIndex == index ? 'activeTabs' : ''"
            @click="changeTabs(item, index)"
          >
            <div class="languageName">
              {{ switchLanguage == "zh" ? item.name : item.enName }}
            </div>
          </div>
        </div>
        <div class="header_right_con">
          <div class="language_con" :class="{ black: !flagSwiper }">
            <el-radio-group v-model="switchLanguage" @input="changeLanguage">
              <el-radio-button label="zh">中</el-radio-button>
              <el-radio-button label="en">EN</el-radio-button>
            </el-radio-group>
          </div>
          <div
            class="btn-con"
            v-if="this.watchClientWidth <= 600"
            @click="open"
          >
            <i :class="['draw', { closeDown: openflag }]"></i>
            <i :class="['draw', { miss: openflag }]"></i>
            <i :class="['draw', { closeTop: openflag }]"></i>
          </div>
          <transition name="height">
            <ul class="nav" v-if="openflag">
              <li
                v-for="(item, index) in tabsList"
                :key="index"
                @click="changeMobileTabs(item, index)"
                :class="activeIndex == index ? 'activeTabs' : ''"
              >
                {{ switchLanguage == "zh" ? item.name : item.enName }}
              </li>
            </ul>
          </transition>
        </div>
      </div>
    </div>
    <router-view />
    <div class="footer_con">
      <span>Copyright © 2024 江西合力照明电器有限公司 &nbsp;</span>
      <span>版权所有 |&nbsp;</span>
      <span
        ><a href="https://beian.miit.gov.cn/" target="_blank">
          赣ICP备2023008715号-1</a
        ></span
      >
    </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
const api = "https://website-1308146035.cos.ap-shanghai.myqcloud.com";
export default {
  name: "mobile",
  components: {
    swiper,
    swiperSlide
  },
  data() {
    const _this = this;
    return {
      activeIndex: 0,
      tabsList: [
        {
          name: "关于合力",
          enName: "About HELI",
          url: "/regards"
        },
        {
          name: "照明产品",
          enName: "Lighting Product",
          url: "/product"
        },
        {
          name: "新品发布",
          enName: "New Product Release",
          url: "/newProduct"
        },
        {
          name: "公司特色",
          enName: "Company Characteristics",
          url: "/characteristic"
        },
        {
          name: "全球合作伙伴",
          enName: "Global Partner",
          url: "/global"
        },
        {
          name: "联系我们",
          enName: "Contact Us",
          url: "/relation"
        }
        // {
        //   name: "联系我们详情页",
        //   enName: "Contact Us",
        //   url: "/womenDetail",
        // },
      ],
      switchLanguage: "en",
      watchClientWidth: document.documentElement.clientWidth,
      openflag: false,
      swiperNum: 0,

      bannerList: [
        {
          zhUrl: api + "/banner_zh1.png",
          // enUrl: api + "/banner_en1.png"
        },
        {
          zhUrl: api + "/banner_zh2.png",
          // enUrl: api + "/banner_en2.png"
        },
        {
          zhUrl: api + "/banner_zh3.png",
          // enUrl: api + "/banner_en3.png"
        },
        {
          zhUrl: api + "/banner_zh4.png",
          // enUrl: api + "/banner_en4.png"
        },
        {
          zhUrl: api + "/banner_zh5.png",
          // enUrl: api + "/banner_en5.png"
        },
        {
          zhUrl: api + "/banner_zh6.png",
          // enUrl: api + "/banner_en6.png"
        }
      ],
      bannerList1: [
        {
          // zhUrl: api + "/banner_zh1.png",
          enUrl: api + "/banner_en1.png"
        },
        {
          // zhUrl: api + "/banner_zh2.png",
          enUrl: api + "/banner_en2.png"
        },
        {
          // zhUrl: api + "/banner_zh3.png",
          enUrl: api + "/banner_en3.png"
        },
        {
          // zhUrl: api + "/banner_zh4.png",
          enUrl: api + "/banner_en4.png"
        },
        {
          // zhUrl: api + "/banner_zh5.png",
          enUrl: api + "/banner_en5.png"
        },
        {
          // zhUrl: api + "/banner_zh6.png",
          enUrl: api + "/banner_en6.png"
        }
      ],
      backSwiperOption: {
        // direction: "horizontal", //默认horizontal, 水平轮播
        loop: true, //循环

        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },

        pagination: {
          el: ".swiper-pagination",
          clickable: true
          // type:'fraction'
        },
        on: {
          init: function () {
            const fraction = document.querySelector(".swiper-num");
            fraction.innerHTML = `${this.realIndex + 1} / ${
              _this.bannerList.length
            }`;
          },
          slideChange: function () {
            // this.$parent.swiperNum = this.realIndex;
            const fraction = document.querySelector(".swiper-num");
            fraction.innerHTML = `${this.realIndex + 1} / ${
              _this.bannerList.length
            }`;
          }
        }
      },
      flagSwiper: true
    };
  },
  watch: {
    $route(to, from) {
      console.log("路由发生变化，新的路由为：", to);
      console.log(from, "from");
      if (to.fullPath.includes("womenDetail")) {
        localStorage.setItem("flagSwiper", false);
        this.flagSwiper = false;
      } else {
        localStorage.setItem("flagSwiper", true);

        this.flagSwiper = true;
        // window.scrollTo(0, 1080);
        window.scrollTo({
          top: this.innerHeight + 2,
          behavior: "smooth"
        });
      }

      // 在这里可以获取到最新的路由信息
    }
  },
  mounted() {
    // localStorage.setItem("language", this.switchLanguage);
    window.addEventListener("resize", () => {
      this.watchClientWidth = document.documentElement.clientWidth;
    });
    this.activeIndex = this.tabsList.findIndex(
      item => item.url == this.$route.path
    );

    this.innerHeight = window.innerHeight;
    console.log(this.innerHeight, "this.innerHeight");

    const flag = localStorage.getItem("flagSwiper");
    if (flag == "false") {
      this.flagSwiper = false;
    } else {
      this.flagSwiper = true;
    }
  },
  methods: {
    changeTabs(item, index) {
      this.activeIndex = index;
      this.$router.push(item.url);
    },
    changeLanguage(value) {
      // localStorage.setItem("language", value);
      console.log(value);
    },
    changeMobileTabs(item, index) {
      this.openflag = !this.openflag;
      this.activeIndex = index;
      this.$router.push(item.url);
    },
    open() {
      this.openflag = !this.openflag;
    }
  }
};
</script>

<style lang="scss" scoped>
#app-mobile {
  font-size: 16px;
  .header_img3 {
    height: 100vh;
  }
  .header_img4 {
    height: 212px;
  }
  .height0 {
    height: 0 !important;
  }
  .header_con {
    padding-top: 1px;
    position: relative;
    .header_banner {
      .header_banner_swiper {
        position: absolute;
        // z-index: -1;
        width: 100%;
        .swiper-slide {
          width: 100%;
          height: 100%;
          .banner_img {
            width: 100%;
            height: 100%;
          }
        }
        ::v-deep {
          .swiper-pagination1 {
            left: 35%;
            bottom: 8%;
          }
          .swiper-pagination2 {
            left: 30%;
            bottom: 8%;
          }
          .swiper-pagination-bullet {
            width: 15px;
            height: 4px;
            border-radius: 3px;
            background-color: #cdcecf;
          }
          .swiper-pagination-bullet-active {
            background-color: #96c33b;
          }
        }
      }
      .swiper-num {
        color: #fff;
      }
      .swiper-num2 {
        position: absolute;
        right: 22%;
        bottom: 7.5%;
        z-index: 99;
        color: #fff;
      }
      .swiper-num1 {
        position: absolute;
        right: 40%;
        bottom: 7.5%;
        z-index: 99;
        color: #fff;
      }
    }
    .header_list1 {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
    }
    .header_list2 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px;
      padding: 0 20px;
    }
    .header_list {
      position: absolute;
      left: 0;
      right: 0;
      top: 10px;
      margin: auto;
      z-index: 9999;
      .logo_con {
        margin-right: 10px;
        img {
          width: 150px;
          height: 25px;
        }
      }
      .tabs_con {
        display: flex;
        align-items: center;
        color: #ffffff;
        font-size: 14px;
        margin-right: 10px;
        .tabs_item {
          margin: 0 10px;
          display: flex;
          flex-direction: column;
          align-items: center;
          &::after {
            content: "";
            display: block;
            width: 30px;
            height: 4px;
            margin-top: 5px;
          }
        }
        .languageName {
          text-align: center;
        }
        .activeTabs {
          color: #96c33b;
          &::after {
            content: "";
            display: block;
            width: 50%;
            height: 4px;
            background-color: #96c33b;
            border-radius: 5px;
            margin-top: 5px;
          }
        }
      }
      .black {
        color: #000 !important;
      }
      .header_right_con {
        display: flex;
        align-items: center;
        position: relative;

        .black {
          ::v-deep {
            .el-radio-button__inner {
              color: #000 !important;
            }
          }
        }
        .language_con {
          width: 100px;
          margin-bottom: 10px;
          margin-right: 10px;
          ::v-deep {
            .el-radio-group {
              width: 100%;
              border-radius: 28px;
              border: 2px solid #96c33b;
              height: 26px;
              line-height: 26px;
            }
            .el-radio-button {
              width: 50%;
              :hover {
                background-color: initial;
              }
            }
            .el-radio-button__inner {
              width: 100%;
              // height: 26px;
              border-radius: 28px;
              font-size: 10px;
              background-color: initial;
              border: none;
              box-shadow: none;
              color: #ffffff;
            }
            .is-active {
              background: #96c33b;
              border-radius: 10px;
              height: 100%;
            }
          }
        }
        .btn-con {
          position: relative;
          z-index: 9999;
        }
        .btn-con i.draw {
          display: block;
          width: 30px;
          height: 2px;
          background: #fff;
          transition: 0.3s;
        }
        .btn-con i.draw:first-of-type,
        .btn-con i.draw:last-of-type {
          display: block;
          width: 30px;
          height: 2px;
          background: #fff;
          position: absolute;
          display: block;
          outline: none;
          top: -10px;
          transition: 0.3s;
        }
        .btn-con i.draw:last-of-type {
          top: 10px;
        }

        .btn-con .draw.miss {
          background: transparent;
        }
        .btn-con i.draw.closeDown {
          top: 0;
          transform: rotate(45deg);
        }
        .btn-con i.draw.closeTop {
          top: 0;
          transform: rotate(-45deg);
        }
        .nav {
          width: 100vw;
          padding: 20px 20px 20px 20px;
          // box-sizing: border-box;
          background: rgba(0, 0, 0, 0.8);
          position: absolute;
          top: 42px;
          right: -20px;
          z-index: 999;
          overflow: hidden;
        }
        .height-enter-active,
        .height-leave-active {
          transition: height 0.3s linear;
        }

        .height-enter,
        .height-leave-to {
          height: 0;
        }

        .height-enter-to,
        .height-leave {
          height: 330px;
        }
        .nav li {
          color: #a8a8ab;
          text-decoration: none;
          display: block;
          font-size: 18px;
          height: 50px;
          line-height: 50px;
        }
        .activeTabs {
          color: #96c33b !important;
        }
      }
    }
  }
  .footer_con {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    // height: 30px;
    background-color: #000000;
    color: #dbdcdf;
    font-size: 12px;
    padding: 10px 0;
    span {
      white-space: nowrap;
      line-height: 20px;
    }
    a {
      color: #dbdcdf;
      font-size: 12px;
      &:hover {
        color: #fff;
      }
    }
  }
  ul,
  li {
    list-style: none;
  }
  a {
    color: #666666;
    text-decoration: none;
    font-size: 16px;
  }
}
</style>
