var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app-mobile"}},[_c('div',{staticClass:"header_con",class:{
      header_img3: this.watchClientWidth > 600,
      header_img4: this.watchClientWidth <= 600,
      height0: !_vm.flagSwiper
    }},[(_vm.flagSwiper)?_c('div',{ref:"header_banner",staticClass:"header_banner"},[_c('swiper',{directives:[{name:"show",rawName:"v-show",value:(_vm.switchLanguage == 'zh'),expression:"switchLanguage == 'zh'"}],staticClass:"header_banner_swiper",class:this.watchClientWidth > 600 ? 'header_img3' : 'header_img4',attrs:{"options":_vm.backSwiperOption}},[_vm._l((_vm.bannerList),function(item,index){return _c('swiper-slide',{key:index,staticClass:"swiper-slide"},[_c('img',{staticClass:"banner_img",attrs:{"src":item.zhUrl,"alt":""}})])}),_c('div',{staticClass:"swiper-pagination",class:this.watchClientWidth > 600
              ? 'swiper-pagination1'
              : 'swiper-pagination2',attrs:{"slot":"pagination"},slot:"pagination"})],2),_c('swiper',{directives:[{name:"show",rawName:"v-show",value:(_vm.switchLanguage == 'en'),expression:"switchLanguage == 'en'"}],staticClass:"header_banner_swiper",class:this.watchClientWidth > 600 ? 'header_img3' : 'header_img4',attrs:{"options":_vm.backSwiperOption}},[_vm._l((_vm.bannerList1),function(item,index){return _c('swiper-slide',{key:index,staticClass:"swiper-slide"},[_c('img',{staticClass:"banner_img",attrs:{"src":item.enUrl,"alt":""}})])}),_c('div',{staticClass:"swiper-pagination",class:this.watchClientWidth > 600
              ? 'swiper-pagination1'
              : 'swiper-pagination2',attrs:{"slot":"pagination"},slot:"pagination"})],2),_c('div',{staticClass:"swiper-num",class:this.watchClientWidth > 600 ? 'swiper-num2' : 'swiper-num1'})],1):_vm._e(),_c('div',{staticClass:"header_list",class:this.watchClientWidth > 600 ? 'header_list1' : 'header_list2'},[_vm._m(0),(this.watchClientWidth > 600)?_c('div',{staticClass:"tabs_con",class:{ black: !_vm.flagSwiper }},_vm._l((_vm.tabsList),function(item,index){return _c('div',{key:index,staticClass:"tabs_item",class:_vm.activeIndex == index ? 'activeTabs' : '',on:{"click":function($event){return _vm.changeTabs(item, index)}}},[_c('div',{staticClass:"languageName"},[_vm._v(" "+_vm._s(_vm.switchLanguage == "zh" ? item.name : item.enName)+" ")])])}),0):_vm._e(),_c('div',{staticClass:"header_right_con"},[_c('div',{staticClass:"language_con",class:{ black: !_vm.flagSwiper }},[_c('el-radio-group',{on:{"input":_vm.changeLanguage},model:{value:(_vm.switchLanguage),callback:function ($$v) {_vm.switchLanguage=$$v},expression:"switchLanguage"}},[_c('el-radio-button',{attrs:{"label":"zh"}},[_vm._v("中")]),_c('el-radio-button',{attrs:{"label":"en"}},[_vm._v("EN")])],1)],1),(this.watchClientWidth <= 600)?_c('div',{staticClass:"btn-con",on:{"click":_vm.open}},[_c('i',{class:['draw', { closeDown: _vm.openflag }]}),_c('i',{class:['draw', { miss: _vm.openflag }]}),_c('i',{class:['draw', { closeTop: _vm.openflag }]})]):_vm._e(),_c('transition',{attrs:{"name":"height"}},[(_vm.openflag)?_c('ul',{staticClass:"nav"},_vm._l((_vm.tabsList),function(item,index){return _c('li',{key:index,class:_vm.activeIndex == index ? 'activeTabs' : '',on:{"click":function($event){return _vm.changeMobileTabs(item, index)}}},[_vm._v(" "+_vm._s(_vm.switchLanguage == "zh" ? item.name : item.enName)+" ")])}),0):_vm._e()])],1)])]),_c('router-view'),_vm._m(1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo_con"},[_c('img',{attrs:{"src":"https://website-1308146035.cos.ap-shanghai.myqcloud.com/logo_zh.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer_con"},[_c('span',[_vm._v("Copyright © 2024 江西合力照明电器有限公司  ")]),_c('span',[_vm._v("版权所有 | ")]),_c('span',[_c('a',{attrs:{"href":"https://beian.miit.gov.cn/","target":"_blank"}},[_vm._v(" 赣ICP备2023008715号-1")])])])
}]

export { render, staticRenderFns }